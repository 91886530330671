<template>
  <vs-row>
    <vs-col>
      <div>
        <vx-card class="my-5">
          <!-- MonthlyInvoiceId: null,
      UserId: null,
      Year: null,
      Month: null,
      Status: null -->
          <div class="flex justify-center">
            <vs-input
              v-model="MonthlyInvoiceId"
              class="mx-8"
              label-placeholder="MonthlyInvoiceId"
              @keyup.enter="getinvoicelist()"
            ></vs-input>
            <vs-input
              v-model="UserId"
              class="mx-8"
              label-placeholder="UserId"
              @keyup.enter="getinvoicelist()"
            ></vs-input>
            <vs-input
              v-model="Month"
              class="mx-8"
              label-placeholder="Month"
              @keyup.enter="getinvoicelist()"
            ></vs-input>
            <vs-input
              v-model="Year"
              class="mx-8"
              label-placeholder="Year"
              @keyup.enter="getinvoicelist()"
            ></vs-input>
          </div>
          <vs-button class="mt-5" @click="getinvoicelist()" size="large" icon="search"> </vs-button>
        </vx-card>
        <vx-card>
          <div class="flex">
            <!-- <vs-button @click="log">log</vs-button> -->
            <vs-pagination :total="page_count" v-model="page"></vs-pagination>
          </div>
          <vs-table v-model="selectedinvoice" :data="invoicelist">
            <template slot="header">
              <div class="mb-3"></div>
            </template>

            <template slot="thead">
              <vs-th> ID فاکتور </vs-th>
              <vs-th> شماره فاکتور </vs-th>
              <vs-th> کاربر </vs-th>
              <vs-th> نوع فاکتور </vs-th>
              <vs-th> مصرف شده </vs-th>
              <vs-th> مالیات </vs-th>
              <vs-th> کل مصرف اعتبار </vs-th>
              <vs-th> وضعیت پرداخت </vs-th>
              <vs-th> نوع پرداخت </vs-th>
              <vs-th> از تاریخ </vs-th>
              <vs-th> تا تاریخ </vs-th>
              <vs-th>
                <vs-button
                  v-if="Status != -1"
                  @click="Status = -1"
                  color=""
                  type="border"
                  radius
                  icon="X"
                  size="small"
                ></vs-button>
                <vs-button
                  icon="sort"
                  :color="Status == -1 ? '' : Status ? 'danger' : 'success'"
                  @click="!Status ? (Status = 1) : (Status = 0)"
                  type="line"
                  >وضعیت فاکتور</vs-button
                >
              </vs-th>
              <vs-th> صدور فاکتور رسمی </vs-th>
              <vs-th> توضیحات </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].id">
                  {{ data[indextr].id }}
                </vs-td>
                <vs-td :data="data[indextr].invoice_number">
                  {{ data[indextr].invoice_number }}
                </vs-td>
                <vs-td :data="data[indextr].user_name">
                  <span :class="data[indextr].is_legal ? 'text-warning' : ''">
                    {{
                      data[indextr].is_legal
                        ? data[indextr].user.company_name
                        : data[indextr].user.name
                    }}
                  </span>
                </vs-td>
                <vs-td
                  :class="data[indextr].invoice_type ? 'text-warning' : ''"
                  :data="data[indextr].invoice_type_text"
                >
                  {{ data[indextr].invoice_type_text }}
                </vs-td>
                <vs-td :data="data[indextr].amount">
                  {{ data[indextr].amount }}
                </vs-td>
                <vs-td :data="data[indextr].tax_percent">
                  {{ `${data[indextr].tax}` }}
                </vs-td>
                <vs-td
                  :class="
                    data[indextr].amount == data[indextr].final_amount
                      ? 'text-danger'
                      : ''
                  "
                  :data="data[indextr].final_amount"
                >
                  {{ data[indextr].final_amount }}
                </vs-td>
                <vs-td
                  :class="
                    data[indextr].pay_status ? 'text-success' : 'text-danger'
                  "
                  :data="data[indextr].pay_status_text"
                >
                  {{ data[indextr].pay_status_text }}
                </vs-td>
                <vs-td :data="data[indextr].pay_type_text">
                  {{ data[indextr].pay_type_text }}
                </vs-td>
                <vs-td :data="data[indextr].date">
                  {{
                    `${data[indextr].jalali_year}-${data[indextr].jalali_month}`
                  }}
                </vs-td>
                <vs-td :data="data[indextr].created_at">
                  {{ data[indextr].created_at }}
                </vs-td>
                <vs-td :data="data[indextr].request_document_text">
                  <span
                    :class="data[indextr].request_document ? 'text-danger' : ''"
                    >{{ data[indextr].request_document_text }}</span
                  >
                </vs-td>
                <vs-td>
                  <div class="flex">
                    <vs-button
                      color=""
                      type="border"
                      class="mx-1"
                      @click="gotoprintpage(data[indextr].id)"
                      radius
                      icon="print"
                    ></vs-button>
                    <vs-button
                      :color="
                        data[indextr].request_document ? 'primary' : '#212744'
                      "
                      @click="
                        SendInvoiceData.user_id = data[indextr].user_id;
                        SendInvoiceData.monthly_invoice_id = data[indextr].id;
                        sendinvoicepopup = !sendinvoicepopup;
                      "
                      :disabled="data[indextr].request_document ? false : true"
                      >Send</vs-button
                    >
                  </div>
                </vs-td>
                <vs-td :data="data[indextr].tax_percent">
                  {{ JSON.parse(data[indextr].description) }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
        <vs-pagination class="my-3" :total="page_count" v-model="page"></vs-pagination>
        <!-- <p class="my-3">{{ selectedinvoice }}</p> -->
        <vue-json-pretty :data="selectedinvoice"> </vue-json-pretty>
        <vs-popup
          @cancel="sendinvoicepopup = false"
          @close="sendinvoicepopup = false"
          :active.sync="sendinvoicepopup"
        >
          <p class="text-xl text-warning">
            برای اطلاع رسانی به کاربر * {{ SendInvoiceData.user_id }} * از وضعیت
            فاکتور رسمی * {{ SendInvoiceData.monthly_invoice_id }} * فیلد زیر را
            پر کنید
          </p>
          <vs-textarea
            class="my-5"
            v-model="SendInvoiceData.description"
          ></vs-textarea>
          <vs-button
            v-if="SendInvoiceData.description"
            @click="
              sendinvoicestatus({
                description: SendInvoiceData.description,
                monthly_invoice_id: SendInvoiceData.monthly_invoice_id
              });
              sendinvoicepopup = !sendinvoicepopup;
            "
            color="warning"
            class="w-full"
            >آپدیت وضعیت</vs-button
          >
        </vs-popup>
      </div>
    </vs-col>
  </vs-row>
</template>
<script>
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
export default {
  components: {
    VueJsonPretty
  },
  data() {
    return {
      page_count: 0,
      page: 1,
      selectedinvoice: {},
      sendinvoicepopup: false,
      SendInvoiceData: {}, //SendInvoiceData{user_id : number, Description: text}
      invoicelist: [],
      MonthlyInvoiceId: "",
      UserId: "",
      Year: "",
      Month: "",
      Status: -1,
    };
  },
  watch: {
    page() {
      this.getinvoicelist();
    },
    Status() {
      this.getinvoicelist();
    },
    selectedinvoice() {
      console.log(this.selectedinvoice);
    }
  },
  methods: {
    getinvoicelist() {
      const data = {
        monthly_invoice_id: this.MonthlyInvoiceId,
        user_id: this.UserId,
        year: this.Year,
        month: this.Month,
        status: this.Status,
        page: this.page
      };
      this.$vs.loading()
      this.$http
        .post("/admin/invoice/monthly/list", data)
        .then(res => {
          this.$vs.loading.close()
          // console.log(res);
          this.page_count = res.data.params.last_page
          this.invoicelist = res.data.params.data;
        })
        .catch(err => {
          this.$vs.loading.close()
          console.log(err);
        });
    },
    gotoprintpage(id) {
      // console.log(id);
      this.$router.push(`/printinvoicelist/${id}`);
    },
    sendinvoicestatus(data) {
      // console.log(data);
      this.$http
        .post("/admin/invoice/monthly/update/doc/request", data)
        .then(res => {
          this.$vs.notify({ text: res.data.message, color: "success" });
          // console.log(res.data.params.data);
        })
        .catch(err => {
          this.$vs.notify({ text: err.response.data.message, color: "danger" });
          console.log(err);
        });
    }
  },
  created() {
    this.getinvoicelist();
  }
};
</script>
